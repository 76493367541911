import { useRef, useState } from 'react';
import classNames from 'classnames';
import { noop } from 'mycs/shared/utilities/GeneralUtils/GeneralUtils';

import { useLocale } from 'mycs/shared/state/LocaleContext';
import { useDevice } from 'mycs/router/DeviceContext';
import AnalyticsService from 'mycs/shared/services/AnalyticsService/AnalyticsService';
import Button from 'mycs/shared/components/Button/Button';
import SmartImage from 'mycs/shared/components/SmartImage/SmartImage';

import styles from './Video.scss';
import { CfVideo } from '@mycs/contentful';

type Props = {
  autoPlay?: boolean;
  buttonLink?: string;
  buttonProps?: {};
  buttonText?: string;
  centeredLayout?: boolean;
  className?: string;
  darkLayout?: boolean;
  hideTitle?: boolean;
  id?: string;
  loop?: boolean;
  muted?: boolean;
  onClose?: () => void;
  text?: string;
  title?: string;
  video?: string;
  videoPoster?: string;
  whiteLayout?: boolean;
  displayTitleAndTextOverlay?: CfVideo['displayTitleAndTextOverlay'];
  content?: CfVideo;
};

export default function Video({
  autoPlay = false,
  buttonLink,
  buttonProps,
  buttonText,
  centeredLayout,
  className,
  darkLayout = false,
  hideTitle = false,
  id,
  loop = false,
  muted,
  onClose = noop,
  text,
  title,
  video,
  videoPoster,
  whiteLayout = false,
  content,
  displayTitleAndTextOverlay,
}: Props) {
  const { locale } = useLocale();
  const device = useDevice();
  const videoId = content?.anchor ? content?.anchor : id;
  const poster = content ? content.videoPoster?.url : videoPoster;
  const videoText = content?.text ? content.text : text;
  const videoTitle = content?.title ? content.title : title;
  const [isInitial, setIsInitial] = useState(true);
  const [isPaused, setIsPaused] = useState(true);
  const videoRef = useRef<HTMLVideoElement>(null);
  // Can be passed directly or trough content props depending on the use case
  const displayTitleAndTextOverlayProp = displayTitleAndTextOverlay
    ? displayTitleAndTextOverlay
    : content?.displayTitleAndTextOverlay;
  const isTextOnVideo =
    !displayTitleAndTextOverlayProp ||
    displayTitleAndTextOverlayProp === 'Overlay on video';

  const classes = classNames('contentContainer', className, styles.container, {
    [styles.whiteLayout]: whiteLayout,
    [styles.centeredLayout]: centeredLayout,
    [styles.darkLayout]: darkLayout,
    [styles.initial]: isInitial,
    [styles.paused]: isPaused,
    [styles.playing]: !isPaused,
  });

  // If displayTitleAndTextOverlay is not p

  if (content) {
    const {
      video: { url: videoUrl = '' } = {},
      videoMobile: { url: videoMobile } = {},
      videoTablet: { url: videoTablet } = {},
    } = content;
    // Select video URL based on device if "content" video prop is present
    // otherwise use the video URL props
    if (device.hasPhoneDimensions && videoMobile) {
      video = videoMobile;
    } else if (device.hasTabletDimensions && videoTablet) {
      video = videoTablet;
    } else if (videoUrl) {
      video = videoUrl;
    }
  }

  const onPlay = () => {
    setIsPaused(false);
    AnalyticsService.eventTrack('videoClick', locale, {
      action: 'play',
    });
  };

  const onPause = () => {
    setIsPaused(true);
    AnalyticsService.eventTrack('videoClick', locale, {
      action: 'pause',
    });
  };

  const onEnd = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.pause();
    }
    setIsInitial(true);
    setIsPaused(true);
    AnalyticsService.eventTrack('videoFinished', locale);
    if (onClose) {
      onClose();
    }
  };

  const handleOnClose = () => {
    onEnd();
    onClose?.();
  };

  const renderTitle = () => {
    return !hideTitle && videoTitle ? (
      <h3 className={styles.title}>{videoTitle}</h3>
    ) : null;
  };

  const renderButton = () => {
    return buttonText && buttonLink ? (
      <Button className={styles.button} href={buttonLink} {...buttonProps}>
        {buttonText}
      </Button>
    ) : null;
  };

  const onPlayButtonClick = () => {
    if (isInitial) {
      setIsInitial(false);
    }
    videoRef.current?.play();
  };

  return (
    <>
      <div className={classes} id={videoId} data-testid="video-container">
        <div
          className={classNames(styles.videoContainer, {
            [styles.videoNoText]: !videoText,
          })}
        >
          <div className={styles.videoWrapper}>
            <div className={styles.closeIcon} onClick={handleOnClose}>
              <Button iconName="general/close.svg" />
            </div>
            <div className={styles.overlay} />
            <video
              ref={videoRef}
              role="video"
              className={styles.video}
              src={video}
              autoPlay={autoPlay}
              muted={muted}
              onPlay={onPlay}
              onPause={onPause}
              onEnded={onEnd}
              controls
              loop={loop}
            />
            <div className={styles.playIcon} onClick={onPlayButtonClick}>
              <Button iconName="general/play-circle.svg" />
            </div>
          </div>
          {poster && (
            <SmartImage
              className={styles.overlayImage}
              src={poster}
              alt={videoTitle}
              title={videoTitle}
            />
          )}
        </div>

        <div className={styles.content}>
          {isTextOnVideo && (
            <>
              {renderTitle()}
              {videoText ? (
                <div className={styles.text}>{videoText}</div>
              ) : null}
            </>
          )}

          {renderButton()}
        </div>
        {videoPoster && (
          <SmartImage
            className={styles.overlayImage}
            src={videoPoster}
            alt={title}
            title={title}
          />
        )}
      </div>
      {!isTextOnVideo && (
        <div className={styles.textContainer}>
          {!hideTitle && <h3 className={styles.title}>{videoTitle}</h3>}
          <p>{videoText}</p>
        </div>
      )}
    </>
  );
}
