import classNames from 'classnames';

import styles from './DiscountBadge.scss';

type Props = {
  discountRate?: string;
  className?: string;
};

export default function DiscountBadge({ discountRate, className }: Props) {
  const discountPercentage = discountRate ? `-${discountRate}` : '%';

  return (
    <div className={classNames(styles.container, className)}>
      <span>{discountPercentage}</span>
    </div>
  );
}
