import classNames from 'classnames';

import styles from './ButtonGroup.scss';

type Props = {
  left: React.ReactNode;
  right: React.ReactNode;
  className?: string;
};

/**
 * Render a group of buttons
 */
export default function ButtonGroup({ left, right, className }: Props) {
  return (
    <div className={classNames(styles.container, className)}>
      <div>{left}</div>

      <div className={styles.iconButton}>{right}</div>
    </div>
  );
}
