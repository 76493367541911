import { PureComponent, ReactNode } from 'react';
import classNames from 'classnames';

import { useDevice } from 'mycs/router/DeviceContext';
import Button from 'mycs/shared/components/Button/Button';
import FitImage from 'mycs/shared/components/FitImage/FitImage';
import PinchPanZoom from 'mycs/shared/components/PinchPanZoom/PinchPanZoom';
import ReactModal from 'mycs/shared/utilities/ReactModal';
import SmartImage from 'mycs/shared/components/SmartImage/SmartImage';

import closeSvg from 'mycs-img/icons/general/close.svg';
import styles from './ImageDetailModal.scss';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  zoomMax: number;
  imageUrl: string;
  closeTimeout?: number;
}

const defaultProps = {
  closeTimeout: 300,
} as Props;

interface State {
  zoomScale: number;
  zoomPanning: { x: number; y: number };
  zoomAnimate: boolean;
  isMounted: boolean;
  imageSize: { width: number };
}

export default function ImageDetailModal(props: Props) {
  const { hasPhoneDimensions } = useDevice();

  return (
    <InnerImageDetailModal {...props} isSmallScreen={hasPhoneDimensions} />
  );
}

type InnerProps = Props & {
  isSmallScreen: boolean;
};

export class InnerImageDetailModal extends PureComponent<InnerProps, State> {
  static defaultProps = defaultProps;

  state = {
    zoomScale: 1.0,
    zoomPanning: { x: 0, y: 0 },
    zoomAnimate: false,
    isMounted: false,
    imageSize: { width: 0, height: 0 },
  };

  /**
   * update panning, scale, and animate state variables
   */
  updateZoom = ({
    scale,
    panning,
    animate,
  }: {
    scale?: number;
    panning?: { x: number; y: number };
    animate?: boolean;
  }) => {
    let { zoomScale, zoomPanning, zoomAnimate } = this.state;

    if (typeof scale !== 'undefined') {
      zoomScale = scale;
    }
    if (typeof animate !== 'undefined') {
      zoomAnimate = animate;
    }

    if (typeof panning !== 'undefined') {
      zoomPanning = panning;
    } else if (scale === 1) {
      zoomPanning = { x: 0, y: 0 };
    }

    this.setState({ zoomScale, zoomPanning, zoomAnimate });
  };

  onClose = () => {
    // Reset zoom/pan state.
    setTimeout(() => {
      this.setState({ zoomScale: 1.0, zoomPanning: { x: 0, y: 0 } });
      this.props.onClose();
    }, this.props.closeTimeout);
  };

  renderWithFitImage = (children: ReactNode): ReactNode => (
    <FitImage top={0} bottom={0} left={0} right={0} {...this.state.imageSize}>
      {children}
    </FitImage>
  );

  componentDidMount() {
    // We want to avoid rendering this component on the server
    setTimeout(() => {
      // Make it fit into the shortest side
      const { innerHeight, innerWidth } = window;
      const shortest = innerHeight > innerWidth ? innerWidth : innerHeight;
      const imageSize = {
        width: shortest,
      };
      this.setState({ isMounted: true, imageSize });
    }, 0);
  }

  render(): ReactNode {
    if (!this.state.isMounted) return null;

    const { zoomScale, zoomPanning, zoomAnimate } = this.state;
    const { closeTimeout, isOpen, zoomMax, imageUrl } = this.props;

    if (!imageUrl) return null;

    const imageClasses = classNames(styles.modalImage, {
      [styles.zoomedIn]: zoomScale > 1,
      [styles.zoomedOut]: zoomScale === 1,
    });

    const image = (
      <PinchPanZoom
        disabled={false}
        maxScale={zoomMax}
        scale={zoomScale}
        panning={zoomPanning}
        animate={zoomAnimate}
        onZoomUpdate={this.updateZoom}
        singleTapToZoom
        fitImageScale={1}
        top={0}
        bottom={0}
        left={0}
        right={0}
        {...this.state.imageSize}
      >
        <SmartImage className={imageClasses} src={imageUrl} />
      </PinchPanZoom>
    );

    return (
      <ReactModal
        isOpen={isOpen}
        className={styles.modal}
        overlayClassName={styles.overlay}
        closeTimeoutMS={closeTimeout}
      >
        <div>
          <Button
            iconContent={closeSvg}
            className={styles.closeIcon}
            onClick={this.onClose}
          />
          {this.props.isSmallScreen ? this.renderWithFitImage(image) : image}
        </div>
      </ReactModal>
    );
  }
}
