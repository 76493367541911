import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import useSWR from 'swr';
import { CfLaminatesConfig } from '@mycs/contentful';

import type { Design } from 'mycs/shared/services/DesignApiService/DesignApiService';
import { RelativeUrlService } from 'mycs/shared/services/RelativeUrlService';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import ContentfulService from 'mycs/shared/services/ContentfulService/ContentfulService';
import DesignApiService from 'mycs/shared/services/DesignApiService/DesignApiService';

export function useMaterialConfig() {
  const pdpPage = '/product';
  const { pathname } = useLocation();
  const { countryCode, locale } = useLocale();
  const { data: materialConfigs } = useSWR('materialConfigs', () =>
    ContentfulService.getMaterialConfigs(locale, countryCode)
  );

  let currentPage = RelativeUrlService.getUntranslatedUrl(pathname, locale);

  if (RelativeUrlService.isPdpPage(pathname, locale)) {
    currentPage = pdpPage;
  }

  const hasMaterialsFromConfig = useCallback(
    (config: CfLaminatesConfig, designMaterials: Design['colors']) => {
      const flattenedMaterials =
        DesignApiService.flattenDesignMaterials(designMaterials);
      // No materials is considered 'all materials'
      if (!config?.materials || config.materials.length === 0) return true;

      return !!config.materials?.some((configMaterial) =>
        flattenedMaterials.some(
          (designMaterial) => configMaterial === designMaterial
        )
      );
    },
    []
  );

  const getConfigsWithFurnitureTypeAndDate = useCallback(
    (furnitureType: string, designCreatedAt?: string) => {
      return materialConfigs?.filter((config) => {
        const hasFurnitureType = config.furnitureTypes?.includes(furnitureType);

        const afterDeprecation =
          config.deprecationDate === undefined || designCreatedAt === undefined
            ? true
            : new Date(config.deprecationDate).getTime() >
              new Date(designCreatedAt).getTime();

        return hasFurnitureType && afterDeprecation;
      });
    },
    [materialConfigs]
  );

  const getHighestPrioConfig = (configs: CfLaminatesConfig[]) => {
    const maxPriority = Math.max(
      ...configs.map((config) => config.priority ?? -1)
    );
    const maxPriorityConfig = configs.find(
      (config) => config.priority === maxPriority
    );
    if (!maxPriorityConfig) return configs[0];

    return maxPriorityConfig;
  };

  const getMaterialEOLConfig = useCallback(
    (
      furnitureType: string,
      designMaterials: Design['colors'],
      designCreatedAt?: string,
      isInfoMessage = false
    ) => {
      const configs = getConfigsWithFurnitureTypeAndDate(
        furnitureType,
        designCreatedAt
      );

      if (!configs?.length) return undefined;

      const configsInPage = configs.filter((config) => {
        if (isInfoMessage) {
          return config.infoMessageDisplayPages === undefined
            ? true
            : config.infoMessageDisplayPages?.includes(currentPage);
        }
        return config.labelDisplayPages === undefined
          ? true
          : config.labelDisplayPages?.includes(currentPage);
      });

      const config = getHighestPrioConfig(configsInPage);

      if (!hasMaterialsFromConfig(config, designMaterials)) return undefined;

      return config;
    },
    [currentPage, getConfigsWithFurnitureTypeAndDate, hasMaterialsFromConfig]
  );

  const getMaterialEOLLabel = (
    furnitureType: string,
    designMaterials: Design['colors'],
    designCreatedAt?: string
  ) => {
    const config = getMaterialEOLConfig(
      furnitureType,
      designMaterials,
      designCreatedAt
    );
    if (!config?.labelText) return undefined;
    return config.labelText;
  };

  const getMaterialEOLInfoMessage = (
    furnitureType: string,
    designMaterials: Design['colors'],
    designCreatedAt?: string
  ) => {
    const config = getMaterialEOLConfig(
      furnitureType,
      designMaterials,
      designCreatedAt,
      true
    );
    if (!config?.infoMessage) return undefined;
    return config.infoMessage;
  };

  const getMaterialEOLCTAMessage = (
    furnitureType: string,
    designMaterials: Design['colors'],
    designCreatedAt?: string
  ) => {
    const config = getMaterialEOLConfig(
      furnitureType,
      designMaterials,
      designCreatedAt
    );
    if (!config?.ctaMessage) return undefined;
    return config.ctaMessage;
  };

  const getHighesPrioConfigFromDesigns = useCallback(
    (designs: any, isInfoMessage = false) => {
      const configs = [];
      for (let i = 0; i < designs.length; i++) {
        const design = designs[i];
        const config = getMaterialEOLConfig(
          design.furniture_type,
          design.colors,
          design.created_at,
          isInfoMessage
        );
        if (config) {
          configs.push(config);
        }
      }
      return getHighestPrioConfig(configs);
    },
    [getMaterialEOLConfig]
  );

  return {
    getMaterialEOLInfoMessage,
    getMaterialEOLLabel,
    getMaterialEOLCTAMessage,
    getHighesPrioConfigFromDesigns,
  };
}
