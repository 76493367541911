import isString from 'lodash/isString';
import { Component, createRef, forwardRef } from 'react';
import Slider, { LazyLoadTypes } from 'react-slick';
import classNames from 'classnames';

import { CfVideo } from '@mycs/contentful';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import AnalyticsService from 'mycs/shared/services/AnalyticsService/AnalyticsService';
import Icon from 'mycs/shared/components/Icon/Icon';
import Scrollable from 'mycs/shared/components/Scrollable/Scrollable';
import SmartImage from 'mycs/shared/components/SmartImage/SmartImage';

import styles from './Carousel.scss';
import CfImage from '../CfImage/CfImage';

export interface VideoPreview {
  video: CfVideo;
  onClick?: Function;
}

interface Props {
  afterChange?: (index: number) => void;
  beforeChange?: (index: number) => void;
  adaptiveHeight?: boolean;
  arrows?: boolean;
  autoplay?: boolean;
  autoplaySpeed?: number;
  centerMode?: boolean;
  children?: React.ReactNode;
  dots?: boolean;
  previews?: React.ReactNode[] | string[] | null;
  videoPreviews?: VideoPreview[] | null;
  draggable?: boolean;
  fade?: boolean;
  fullWidth?: boolean;
  infinite?: boolean;
  lazyLoad?: LazyLoadTypes;
  speed?: number;
  slidesToShow?: number;
  slidesToScroll?: number;
  swipe?: boolean;
  useCSS?: boolean;
  trackPath?: string;
  currentIndex?: number;
  className?: string;
}

interface State {
  currentIndex: number;
}

const defaultProps: Partial<Props> = {
  adaptiveHeight: false,
  beforeChange: () => null,
  afterChange: () => null,
  arrows: true,
  autoplay: false,
  autoplaySpeed: 4000,
  centerMode: false,
  dots: true,
  draggable: false,
  fade: false,
  fullWidth: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipe: true,
  useCSS: true,
  trackPath: 'Page',
};

const carousel = forwardRef<InnerCarousel, Props>(function Carousel(
  props: Props = defaultProps,
  ref
) {
  const { locale } = useLocale();

  return <InnerCarousel ref={ref} {...props} locale={locale} />;
});

carousel.displayName = 'Carousel';
export default carousel;

type InnerProps = Props & {
  locale: string;
};

export class InnerCarousel extends Component<InnerProps, State> {
  slider = createRef<Slider>();
  hasClicked: boolean | null = null;
  iconName: string | null = null;
  container = createRef<HTMLDivElement>();

  static defaultProps = defaultProps;

  constructor(props: InnerProps) {
    super(props);

    this.state = {
      currentIndex: 0,
    };
  }

  componentDidUpdate() {
    const { currentIndex } = this.props;
    if (currentIndex || currentIndex === 0) {
      if (currentIndex !== this.state.currentIndex) {
        this.goTo(currentIndex);
      }
    }
  }

  goTo = (index: number) => {
    this.slider.current?.slickGoTo(index);
  };

  beforeChange = (_: number, newIndex: number) => {
    this.props.beforeChange?.(newIndex);
  };

  /**
   * On swipe
   * Trigger a click event
   * (as discussed with BI team)
   */
  onSwipe = () => {
    this.hasClicked = true;

    // Dosen't seems to be a property of slider anymore. Remove if uneeded.
    // Hack to remove double tap on touch devices
    // if (this.slider) {
    //   this.slider.innerSlider.clickable = true;
    // }
  };

  onMouseDown = (e: React.MouseEvent) => {
    if (e && e.currentTarget && e.currentTarget.nodeName === 'BUTTON') {
      this.iconName = e.currentTarget.className.includes('slick-prev')
        ? 'general/arrow-chevron-left'
        : 'general/arrow-chevron-right';
    }
    this.hasClicked = true;
  };

  /**
   * Track slide changes initiated by the user
   */
  afterChange = (index: number) => {
    if (!this.hasClicked || !this.container.current) return;
    this.hasClicked = false;

    this.props.afterChange?.(index);

    const path = AnalyticsService.getComponentPath(this.container.current);
    //@ts-ignore
    const page = Math.ceil(index / this.props.slidesToShow);
    const pathEnd = this.iconName
      ? `/Button{${this.iconName}}`
      : `[${page + 1}]`;

    AnalyticsService.eventTrack('click', this.props.locale, {
      componentPath: `${path}/${this.props.trackPath}${pathEnd}`,
    });

    this.iconName = null;
  };

  renderPreviews(): JSX.Element {
    const { currentIndex } = this.state;
    const { previews, videoPreviews } = this.props;
    // Previews are only displayed on phones for now.
    // Each thumbnail width doesn't exceed 15% of the viewport width.
    const imageSizes = '15vw';

    if (!previews || !previews.length) return <></>;

    let previewElements = previews.map((src, i) => {
      const ref = createRef<HTMLDivElement>();
      // If the preview is a url string: display an image preview
      // Otherwise, display the preview element
      return isString(src) ? (
        <div ref={ref} className={styles.previewContainer} key={i + src}>
          <SmartImage
            className={classNames(styles.preview, {
              [styles.active]: currentIndex === i,
            })}
            src={src}
            sizes={imageSizes}
            onClick={() => this.goTo(i)}
          />
          <div
            className={classNames(styles.overlay, {
              [styles.overlayActive]: currentIndex === i,
            })}
          ></div>
        </div>
      ) : (
        src
      );
    });

    if (videoPreviews) {
      previewElements = previewElements.concat(
        videoPreviews?.map(({ video }, i) => {
          const ref = createRef<HTMLDivElement>();
          return (
            <div
              ref={ref}
              key={video._id}
              className={styles.playIconContainer}
              onClick={() => {
                this.goTo(i + previews.length);
              }}
            >
              {video.videoPoster && (
                <CfImage
                  asset={video.videoPoster}
                  className={classNames(styles.videoPreview, {
                    [styles.active]: currentIndex === i + previews.length,
                  })}
                />
              )}
              <div className={styles.playIcon}>
                <Icon iconName="general/play.svg" />
              </div>
            </div>
          );
        })
      );
    }

    return (
      <div className={styles.previews}>
        <Scrollable currentIndex={currentIndex}>{previewElements}</Scrollable>
      </div>
    );
  }

  render(): React.ReactNode {
    const classes = classNames(styles.slider, {
      [styles.fullWidth]: this.props.fullWidth,
      [styles.arrowsUp]: this.props.previews,
      [styles.centerMode]: this.props.centerMode,
      [styles.scrollRight]:
        this.state.currentIndex ===
          (this.props.children as React.ReactNode[]).length - 1 &&
        this.props.slidesToShow !== this.props.slidesToScroll,
    });

    return (
      <div
        className={classes}
        ref={this.container}
        onMouseDown={this.onMouseDown}
      >
        <Slider
          {...this.props}
          ref={this.slider}
          beforeChange={this.beforeChange}
          afterChange={this.afterChange}
          onSwipe={this.onSwipe}
          swipeToSlide
        />
        {this.renderPreviews()}
      </div>
    );
  }
}
