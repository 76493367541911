import { PureComponent, useState } from 'react';
import classNames from 'classnames';

import { useLocale } from 'mycs/shared/state/LocaleContext';
import DiscountBadge from 'mycs/shared/components/DiscountBadge/DiscountBadge';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';
import UIUtils from 'mycs/shared/utilities/UIUtils/UIUtils';

import styles from './Price.scss';
import Tooltip from '../Tooltip/Tooltip';
import ClickOutside from '../ClickOutside/ClickOutside';

type Props = {
  discountAfter?: boolean;
  discountBefore?: boolean;
  price: number;
  oldPrice?: number;
  decimals?: number;
  block?: boolean;
  inline?: boolean;
  size?: string; //'small', 'medium', 'big', 'inherit',
  vat?: number;
  withDiscountBadge?: boolean;
  withSetDiscount?: boolean;
  withSimpleSetDiscount?: boolean;
  isAnimated?: boolean;
  columnLayout?: boolean;
  isRedLayout?: boolean;
  hideOldPrice?: boolean;
  configuratorLayout?: boolean;
  shippingCost?: number;
  isPdpPrice?: boolean;
};

export default function Price(props: Props) {
  const { locale } = useLocale();

  return <InnerPrice {...props} locale={locale} />;
}

type InnerProps = Props & {
  locale: string;
};

/**
 * Renders price localized for user's country.
 */
class InnerPrice extends PureComponent<InnerProps> {
  priceEl: HTMLSpanElement | null = null;
  prevPrice: number | null = null;
  currentPrice: number | null = null;

  static defaultProps = {
    discountAfter: false,
    discountBefore: false,
    decimals: 0,
    block: false,
    inline: false,
    size: 'small',
    withDiscountBadge: true,
    withSetDiscount: false,
    withSimpleSetDiscount: false,
    isAnimated: true,
  };

  /**
   * Odometer-like animation for the price
   */
  animatePriceChange(oldNumber: number, newNumber: number) {
    const step = 20;
    const maxDuration = 600;
    const duration = Math.min(
      maxDuration,
      Math.round(Math.abs(oldNumber - newNumber))
    );
    let prevVal = 0;

    const setPrice = (val: number) => {
      if (!this.priceEl) return; // in case the component is unmounted
      const price = I18nUtils.localizePrice(
        val,
        this.props.decimals ?? 0,
        this.props.locale
      );
      this.priceEl.textContent = price;
    };

    setTimeout(() => {
      UIUtils.animate(oldNumber, newNumber, duration).subscribe(
        (val) => {
          if (Math.abs(prevVal - val) >= step) {
            prevVal = val;
            setPrice(val);
          }
        },
        () => null,
        () => setPrice(newNumber)
      );
    }, 0);
  }

  /**
   * When component has updated and rendered
   */
  componentDidUpdate(prevProps: InnerProps) {
    this.prevPrice = prevProps.price;
    this.currentPrice = this.props.price;

    if (this.props.isAnimated && this.prevPrice && this.currentPrice) {
      this.animatePriceChange(this.prevPrice, this.currentPrice);
    }
  }

  render() {
    const {
      size,
      block,
      inline,
      discountAfter,
      discountBefore,
      columnLayout,
      price,
      oldPrice,
      withSimpleSetDiscount,
      isRedLayout,
      hideOldPrice,
      configuratorLayout,
      decimals,
      withDiscountBadge,
      locale,
      withSetDiscount,
      vat,
      shippingCost,
      isPdpPrice,
    } = this.props;

    const isOldPriceDifferentThanPrice = oldPrice !== price;
    const classes = classNames(styles.price, {
      [styles.big]: size === 'big',
      [styles.medium]: size === 'medium',
      [styles.small]: size === 'small',
      [styles.block]: block,
      [styles.inline]: inline,
      [styles.discountAfter]: discountAfter,
      [styles.discountBefore]: discountBefore,
      [styles.column]: columnLayout,
      [styles.simpleDiscount]:
        oldPrice && withSimpleSetDiscount && isOldPriceDifferentThanPrice,
      [styles.subcatRedHighlight]:
        isRedLayout && oldPrice && isOldPriceDifferentThanPrice,
      [styles.withoutOldPrice]: hideOldPrice,
      [styles.configuratorLayout]: configuratorLayout,
      [styles.pdpPrice]: isPdpPrice,
    });

    const shouldrenderDiscountBadge =
      !!oldPrice && withDiscountBadge && isOldPriceDifferentThanPrice;
    const shouldrenderOldPrice =
      !!oldPrice && isOldPriceDifferentThanPrice && !hideOldPrice;
    const shouldRenderSetDiscount =
      !!oldPrice && withSetDiscount && isOldPriceDifferentThanPrice;
    const shouldrenderSimpleSetDiscount =
      !!oldPrice && withSimpleSetDiscount && isOldPriceDifferentThanPrice;

    return (
      <span className={classes} itemScope itemType="http://schema.org/Offer">
        {shouldrenderOldPrice && (
          <span className={styles.oldPriceContainer}>
            <span
              className={classNames(styles.oldPrice, {
                [styles.small]: columnLayout,
              })}
            >
              {I18nUtils.localizePrice(oldPrice, decimals ?? 0, locale)}
            </span>
          </span>
        )}

        {shouldrenderDiscountBadge && (
          <div className={styles.discount}>
            <DiscountBadge />
          </div>
        )}

        <span className={styles.mainPrice}>
          {shouldRenderSetDiscount && (
            <span className={styles.setDiscount}>
              {I18nUtils.localize(locale, 'Set price')}
            </span>
          )}

          {shouldrenderSimpleSetDiscount && (
            <span className={styles.setSimpleDiscount} />
          )}

          <span
            ref={(el) => (this.priceEl = el)}
            itemProp="price"
            className={classNames(styles.priceText, 'price--rendering')}
          >
            {I18nUtils.localizePrice(price, decimals ?? 0, locale)}
          </span>
        </span>
        <PriceVAT vat={vat} shippingCost={shippingCost} decimals={decimals} />
      </span>
    );
  }
}

export function PriceVAT({
  vat,
  shippingCost = 0,
  decimals = 0,
  noMarginLeft,
}: {
  vat?: number;
  noMarginLeft?: boolean;
  shippingCost?: number;
  decimals?: number;
}) {
  const [showTooltip, setShowTooltip] = useState(false);
  const { locale } = useLocale();

  const onTooltipTouch = (show: boolean) => () => {
    if (show !== showTooltip) {
      setShowTooltip(show);
    }
  };

  if (!vat) {
    return null;
  }

  const tooltipSafeHtml = shippingCost
    ? I18nUtils.localize(locale, 'price-shipping-costs-tooltip').replace(
        '<SHIPPING_PRICE>',
        I18nUtils.localizePrice(shippingCost, decimals, locale)
      )
    : '';

  return (
    <div
      className={classNames(styles.tax, {
        [styles.taxNoMarginLeft]: noMarginLeft,
      })}
    >
      {`${I18nUtils.localize(locale, 'Incl.')} ${vat}% ${I18nUtils.localize(
        locale,
        'VAT'
      )}`}{' '}
      {shippingCost > 0 && (
        <ClickOutside onClickOutside={onTooltipTouch(false)}>
          <Tooltip
            hint
            content={
              <span dangerouslySetInnerHTML={{ __html: tooltipSafeHtml }} />
            }
            placement="bottom"
            show={showTooltip}
            className={styles.tooltipContainer}
            disableOnClose={true}
          >
            <span
              onMouseEnter={onTooltipTouch(true)}
              onTouchStart={onTooltipTouch(true)}
              onTouchEnd={onTooltipTouch(false)}
              className={styles.iconHoverContainer}
            >
              {I18nUtils.localize(locale, 'price-shipping-costs-warning-text')}
            </span>
          </Tooltip>
        </ClickOutside>
      )}
    </div>
  );
}
