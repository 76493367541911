import { useLocale } from 'mycs/shared/state/LocaleContext';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';

import styles from './ImageWithLabel.scss';

interface Props {
  imageComponent: JSX.Element;
  label?: string;
  showLabel?: boolean;
}

export default function ImageWithLabel({
  imageComponent,
  label,
  showLabel,
}: Props) {
  const { locale } = useLocale();
  const translatedLabel = I18nUtils.localize(locale, label);
  return (
    <div className={styles.container}>
      {imageComponent}
      {showLabel && label && translatedLabel !== label && (
        <span className={styles.label}>{translatedLabel}</span>
      )}
    </div>
  );
}
