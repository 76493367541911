import Icon from 'mycs/shared/components/Icon/Icon';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';
import styles from './Label.scss';
import { useLocale } from 'mycs/shared/state/LocaleContext';

interface Props {
  text: string;
  iconName?: string;
}

/**
 * Label Component
 */
export default function Label({ text, iconName }: Props) {
  const { locale } = useLocale();

  return (
    <div className={styles.label}>
      {iconName && (
        <Icon
          isInline
          iconName={iconName}
          iconContainerClass={styles.iconContainer}
        />
      )}
      <span>{I18nUtils.localize(locale, text)}</span>
    </div>
  );
}
