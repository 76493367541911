import SmartImage from 'mycs/shared/components/SmartImage/SmartImage';
import { CfAsset } from '@mycs/contentful';

type Props = {
  className?: string;
  asset: CfAsset;
  sizes?: string;
  onClick?: (e: any) => void;
  onMouseOver?: () => void;
};

export default function CfImage({
  asset,
  className,
  sizes,
  onMouseOver,
  onClick,
}: Props) {
  return (
    <SmartImage
      className={className}
      src={asset.url}
      title={asset.title}
      alt={asset.description}
      width={asset.width}
      height={asset.height}
      sizes={sizes}
      onMouseOver={onMouseOver}
      onClick={onClick}
    />
  );
}
