import classNames from 'classnames';
import { Fragment } from 'react';

import { useLocale } from 'mycs/shared/state/LocaleContext';
import { useDevice } from 'mycs/router/DeviceContext';
import Alink from 'mycs/shared/components/Alink/Alink';
import Button from 'mycs/shared/components/Button/Button';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';
import ImagesCarousel from 'mycs/shared/components/ImagesCarousel/ImagesCarousel';
import SmartImage from 'mycs/shared/components/SmartImage/SmartImage';

import styles from './MediaPreview.scss';

type Props = {
  badges?: JSX.Element[];
  button?: JSX.Element;
  classes?: string;
  hasRemoveButton?: boolean;
  imageAlt?: string;
  imageHeight?: number;
  imagesCarousel?: any[] | null;
  imageSizes?: string;
  imageSrc?: string;
  imageTitle?: string;
  imageWidth?: number;
  isBigTile?: boolean;
  isGridBanner?: boolean;
  isMobileVersion?: boolean;
  isRemoved?: boolean;
  onClick?: () => void;
  onRemoveCallback?: (uuid?: string) => void;
  onUndoRemoveCallback?: (uuid?: string) => void;
  productUuid?: string;
  reference?: string;
  showImagesCarousel?: boolean;
  subtitle?: JSX.Element;
  title?: JSX.Element;
};

export default function MediaPreview(props: Props) {
  const { locale } = useLocale();
  const { hasPhoneDimensions } = useDevice();
  const isPhone = hasPhoneDimensions || props.isMobileVersion;

  const {
    badges = [],
    button,
    classes,
    hasRemoveButton = false,
    imageAlt,
    imageHeight,
    imagesCarousel,
    imageSizes,
    imageSrc,
    imageTitle,
    imageWidth,
    isBigTile,
    isGridBanner,
    isRemoved,
    onClick = () => null,
    onRemoveCallback,
    onUndoRemoveCallback,
    productUuid,
    reference,
    showImagesCarousel = false,
    subtitle,
    title,
  } = props;

  const containerClasses = classNames(
    styles.container,
    {
      [styles.bigTile]: isBigTile,
      [styles.gridBanner]: isGridBanner,
      [styles.mobileLayout]: isPhone,
    },
    (classes || '').split(' ')
  );

  const onMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    // If left button is clicked
    if (e.button === 0) {
      //@ts-ignore
      if (e.target.classList.contains('slick-arrow')) {
        return; // ignore clicks on arrows
      }
      onClick?.();
    }
  };

  return (
    <div className={containerClasses}>
      {renderImageBlock()}
      {isRemoved && isPhone && renderRemoveMessage()}
      {renderText()}
      {badges && renderBadges(badges)}
      {hasRemoveButton && renderRemoveButton()}
      {isRemoved && <div className={styles.overlay}></div>}
    </div>
  );

  function renderImageBlock() {
    if (!imageSrc) return null;

    const imageProps = {
      src: imageSrc,
      alt: imageAlt,
      title: imageTitle,
      sizes: imageSizes,
      width: imageWidth,
      height: imageHeight,
    };

    const image = <SmartImage {...imageProps} />;

    if (showImagesCarousel && imagesCarousel && isBigTile && !isPhone) {
      return (
        <div
          className={classNames(styles.imageBlock, styles.imagesCarousel, {
            [styles.gridBannerCarousel]: isGridBanner,
          })}
          onMouseDown={onMouseDown}
        >
          <ImagesCarousel
            images={imagesCarousel}
            imageLink={reference}
            imageSizes={imageSizes}
            fullWidth
            draggable
            imageWidth={imageWidth}
            imageHeight={imageHeight}
          />
          {button}
        </div>
      );
    }

    return (
      <div className={styles.imageBlock} onMouseDown={onMouseDown}>
        {renderLink(image, styles.imageLink)}
        {button}
        {isRemoved && !isPhone && renderRemoveMessage()}
      </div>
    );
  }

  /**
   * Render the title, subtitle and button
   */
  function renderText() {
    if (isRemoved && isPhone) return <Fragment />;

    const titleClass = title && title.props ? title.props.className : null;
    const titleText =
      title && title.props ? title.props.children : <span>{title}</span>;
    const subtitleClass =
      subtitle && subtitle.props ? subtitle.props.className : null;
    const subtitleText =
      subtitle && subtitle.props ? subtitle.props.children : subtitle;

    const subtitleElement = subtitle ? (
      <span className={classNames(styles.subtitle, subtitleClass)}>
        {subtitleText}
      </span>
    ) : null;

    return (
      <div className={styles.text}>
        <span className={classNames(styles.title, titleClass)}>
          {renderLink(titleText)}
        </span>
        {renderLink(subtitleElement)}
      </div>
    );
  }

  /**
   * Wrap a given element with Alink
   * if the prop.reference is specified.
   */
  function renderLink(element: JSX.Element | null, classes = '') {
    if (!element) return null;
    return reference ? (
      <Alink href={reference} className={classes}>
        {element}
      </Alink>
    ) : (
      element
    );
  }

  /**
   * Render badges (ex. Sleeping Sofa badge)
   */
  function renderBadges(badges: JSX.Element[]) {
    if (isRemoved && isPhone) return <Fragment />;

    return <div>{badges}</div>;
  }

  /**
   * Render Remove button
   */
  function renderRemoveButton() {
    return (
      <Button
        className={styles.removeIcon}
        iconName="general/close"
        onClick={() => onRemoveCallback?.(productUuid)}
      />
    );
  }

  /**
   * Render Remove Message Dialog
   */
  function renderRemoveMessage() {
    return (
      <div className={styles.removeMessageContainer}>
        <div className={styles.block}>
          <div className={styles.removeMessageText}>
            {I18nUtils.localize(
              locale,
              'This item has successfully been removed'
            )}
          </div>
          <Button
            className={styles.undoRemoveButton}
            isFlatSecondaryCta
            onClick={() => onUndoRemoveCallback?.(productUuid)}
          >
            {I18nUtils.localize(locale, 'Undo')}
          </Button>
        </div>
      </div>
    );
  }
}
