export const DimensionsType = {
  default: ['length', 'width', 'height'],
  shelf: ['length', 'height', 'width'],
  wardrobe: ['length', 'height', 'width'],
  joyn: ['length', 'width', 'height'],
  pyllow: ['length', 'width', 'height'],
  cushion: ['length', 'width', 'height'],
};

export const DisplayDimensions = {
  default: ['Length', 'Width', 'Height'],
  shelf: ['Width', 'Height', 'Depth'],
  wardrobe: ['Width', 'Height', 'Depth'],
  joyn: ['Width', 'Depth', 'Height'],
  pyllow: ['Width', 'Depth', 'Height'],
  cushion: ['Width', 'Depth', 'Height'],
  baseboardCut: ['Height', 'Depth'],
};
