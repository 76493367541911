import { Fragment, PureComponent } from 'react';
import classNames from 'classnames';

import { CfVideo } from '@mycs/contentful';
import {
  IImageCredit,
  ImageCredit,
} from 'mycs/shared/components/ImageCredit/ImageCredit';
import Alink from 'mycs/shared/components/Alink/Alink';
import Carousel, {
  VideoPreview,
} from 'mycs/shared/components/Carousel/Carousel';
import ImageDetailModal from 'mycs/pdp/components/ImageDetailModal/ImageDetailModal';
import SmartImage from 'mycs/shared/components/SmartImage/SmartImage';
import Video from 'mycs/shared/components/Video/Video';
import VideoOverlay from 'mycs/shared/components/VideoOverlay/VideoOverlay';

import styles from './ImagesCarousel.scss';
import SafeText from '../SafeText/SafeText';
import ImageWithLabel from '../ImageWithLabel/ImageWithLabel';

interface Props {
  images: string[];
  imagesCredits?: IImageCredit[];
  fullWidth?: boolean;
  showPreviews?: boolean;
  slidesToShow?: number;
  autoplay?: boolean;
  withZoom?: boolean;
  videos?: CfVideo[];
  isCenterAligned?: boolean;
  draggable?: boolean;
  imageLink?: string;
  imageSizes?: string;
  className?: string;
  title?: string;
  titleClassName?: string;
  imageLabels?: string[];
  id?: string;
  imageWidth?: number;
  imageHeight?: number;
}

interface State {
  currentSlide: string;
  isModalOpen: boolean;
  isVideoOpen: boolean;
  videoPreviews: VideoPreview[];
  activeVideoIndex: number;
  currentIndex: number;
}

class ImagesCarousel extends PureComponent<Props, State> {
  static defaultProps = {
    images: [],
    withZoom: false,
    isCenterAligned: false,
    videos: [],
  };

  state = {
    currentSlide: '',
    isModalOpen: false,
    isVideoOpen: false,
    activeVideoIndex: 0,
    videoPreviews: [],
    currentIndex: 0,
  };

  onSlideClick(slide: string) {
    if (this.props.withZoom) {
      this.setState({ currentSlide: slide, isModalOpen: true });
    }
  }

  /**
   * Display the video overlay on click on play
   */
  onVideoOpen(activeVideoIndex: number) {
    this.setState({ isVideoOpen: true, activeVideoIndex });
  }

  /**
   * Close the video overlay on click on close
   */
  onVideoClose() {
    this.setState({ isVideoOpen: false });
  }

  /**
   * Set thumbnail previews
   */
  updateVideoPreviews() {
    const { videos } = this.props;
    const videoPreviews: VideoPreview[] = [];
    // Add a video thumbnail
    if (videos && videos.length > 0) {
      videos.forEach((video, videoIndex) => {
        videoPreviews.push({
          video: video,
          onClick: () => this.onVideoOpen(videoIndex),
        });
      });
    }
    this.setState({ videoPreviews });
  }

  /**
   * On mount
   */
  componentDidMount() {
    this.updateVideoPreviews();
  }

  /**
   * If the videos or images change, update the thumbnails again
   */
  componentDidUpdate(prevProps: Props) {
    if (
      this.props.videos !== prevProps.videos ||
      this.props.images !== prevProps.images
    ) {
      this.updateVideoPreviews();
    }
  }

  createSlides() {
    const {
      images,
      imageLink,
      imageSizes,
      imagesCredits,
      videos,
      imageLabels,
      imageHeight,
      imageWidth,
    } = this.props;
    const { currentIndex } = this.state;
    const slides: React.ReactNode[] = [];

    images.map((src, i) => {
      // Image credit
      const imageCreditItem =
        src &&
        imagesCredits &&
        imagesCredits.find((item) => item.imageUrl === src);
      const imageCredit = imageCreditItem && imageCreditItem.credit;

      slides.push(
        <div key={src}>
          <Alink
            href={imageLink}
            className={classNames(styles.linkContainer, {
              [styles.hasImageCredit]: imageCredit,
            })}
          >
            {currentIndex === i &&
            imageLabels &&
            currentIndex <= imageLabels.length - 1 ? (
              <ImageWithLabel
                label={
                  currentIndex <= imageLabels.length - 1
                    ? imageLabels[currentIndex]
                    : undefined
                }
                showLabel={true}
                imageComponent={
                  <SmartImage
                    src={src}
                    sizes={imageSizes}
                    aspectRatio="1/1"
                    height={imageHeight}
                    width={imageWidth}
                  />
                }
              />
            ) : (
              <SmartImage
                src={src}
                sizes={imageSizes}
                onClick={() => this.onSlideClick(images[i])}
                height={imageHeight}
                width={imageWidth}
              />
            )}
            <ImageCredit credit={imageCredit} />
          </Alink>
        </div>
      );
    });

    if (videos) {
      videos.map((video, i) => {
        const isCurrentSlide = currentIndex === i + images.length;
        slides.push(
          <div key={video._id} className={styles.videoContainer}>
            <Video
              video={video.video.url}
              videoPoster={video.videoPoster?.url}
              className={styles.video}
              muted={!isCurrentSlide}
            />
          </div>
        );
      });
    }

    return slides;
  }

  onIndexUpdate = (currentIndex: number) => {
    this.setState({ currentIndex });
  };

  render() {
    const { showPreviews, images, videos, draggable } = this.props;
    const { videoPreviews } = this.state;
    const multipleImages = images.length > 1;
    const slides = this.createSlides();

    const imageDetailOverlay = this.props.withZoom ? (
      <ImageDetailModal
        isOpen={this.state.isModalOpen}
        onClose={() => this.setState({ isModalOpen: false })}
        zoomMax={3}
        imageUrl={this.state.currentSlide}
      />
    ) : null;

    const currentVideo = videos ? videos[this.state.activeVideoIndex] : null;
    const videoOverlay =
      currentVideo && this.state.isVideoOpen ? (
        <VideoOverlay
          video={currentVideo.video.url}
          isOpen={this.state.isVideoOpen}
          onClose={() => this.onVideoClose()}
          darkLayout
          loop
        />
      ) : (
        <Fragment />
      );

    return (
      <div
        className={classNames(this.props.className, {
          [styles.centerAlignment]: this.props.isCenterAligned,
        })}
        id={this.props.id}
      >
        {this.props.title && (
          <SafeText
            content={this.props.title}
            className={this.props.titleClassName}
          />
        )}
        <Carousel
          fullWidth={this.props.fullWidth}
          slidesToShow={this.props.slidesToShow}
          autoplay={multipleImages && this.props.autoplay}
          dots={multipleImages && !showPreviews}
          previews={showPreviews ? this.props.images : null}
          videoPreviews={showPreviews ? videoPreviews : null}
          arrows={multipleImages}
          swipe={multipleImages}
          draggable={draggable}
          beforeChange={this.onIndexUpdate}
        >
          {/** react-slick is always showing the last slide in SSR which causes flickering after hydration */}
          {MYCS_IS_SSR_BUILD ? slides.slice(0, 1) : slides}
        </Carousel>
        {imageDetailOverlay}
        {videoOverlay}
      </div>
    );
  }
}

export default ImagesCarousel;
