import classNames from 'classnames';
import { Fragment } from 'react';

import { noop } from 'mycs/shared/utilities/GeneralUtils/GeneralUtils';
import ClickOutside from 'mycs/shared/components/ClickOutside/ClickOutside';
import ReactModal from 'mycs/shared/utilities/ReactModal';
import Video from 'mycs/shared/components/Video/Video';

import styles from './VideoOverlay.scss';

type Props = {
  video?: string;
  isOpen: boolean;
  onClose: any;
  darkLayout: boolean;
  loop?: boolean;
};

export default function VideoOverlay({
  video,
  isOpen = false,
  onClose = noop,
  darkLayout = false,
  loop = false,
}: Props) {
  if (!isOpen) return <Fragment />;

  const classes = classNames(styles.videoModal, {
    [styles.darkLayout]: darkLayout,
  });
  const overlayClasses = classNames(styles.videoModalOverlay, {
    [styles.darkOverlay]: darkLayout,
  });

  return (
    <ReactModal
      contentLabel="VideoOverlay"
      className={classes}
      overlayClassName={overlayClasses}
      isOpen={isOpen}
    >
      <ClickOutside onClickOutside={onClose}>
        <Video
          video={video}
          muted
          autoPlay
          onClose={onClose}
          className={styles.video}
          centeredLayout
          darkLayout={darkLayout}
          loop={loop}
        />
      </ClickOutside>
    </ReactModal>
  );
}
