import type { Design } from 'mycs/shared/services/DesignApiService/DesignApiService';
import { Fragment } from 'react';

import { useDevice } from 'mycs/router/DeviceContext';
import Carousel from 'mycs/shared/components/Carousel/Carousel';
import ProductPreview from 'mycs/shared/components/ProductPreview/ProductPreview';

import styles from './DesignsCarousel.scss';

type Props = {
  designs: Design[];
  centerMode?: boolean;
  slidesToShow?: number;
  slidesToScroll?: number;
  isPdpLink?: boolean;
  showProductMeasurements?: boolean;
};

export default function DesignsCarousel({
  designs,
  isPdpLink,
  showProductMeasurements = false,
  slidesToShow: _slidesToShow,
  slidesToScroll = 1,
  centerMode = false,
}: Props) {
  const device = useDevice();
  const isSmallScreen = device.hasPhoneDimensions;
  const isTablet = device.isTablet || device.hasTabletDimensions;
  const slidesToShow = _slidesToShow ?? (isSmallScreen ? 1.35 : 3);
  // Sizes for SmartImage (mobile is reduced on purpose in order to get 500*500 resolution)
  const imageSizes = '(max-width: 767px) 10vw, 20vw';

  if (!designs.length) return <Fragment />;

  const slides = designs.map((product, index) => (
    <div
      className={styles.slide}
      key={product.uuid || index}
      data-testid={`product-preview-${product.uuid}`}
    >
      <ProductPreview
        product={product}
        isPdpLink={isPdpLink}
        imageSizes={imageSizes}
        showProductMeasurements={showProductMeasurements}
        isMobileVersion={isSmallScreen || isTablet}
      />
    </div>
  ));

  return (
    <div className={styles.container} data-testid="designs-carousel">
      <Carousel
        slidesToShow={slidesToShow}
        slidesToScroll={slidesToScroll}
        centerMode={centerMode}
        fullWidth={false}
        arrows={!isSmallScreen}
        infinite={false}
        lazyLoad="ondemand"
        draggable
      >
        {slides}
      </Carousel>
    </div>
  );
}
