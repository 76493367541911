import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';
import { DimensionsType, DisplayDimensions } from './DimensionsConfig';

/**
 * This class is used across the project
 * to set the displayed dimensions per furniture type
 *
 */
class DimensionsDisplayUtils {
  /**
   * Returns the dimensions per furniture type
   */
  static getType(furnitureType: string | undefined): any[] {
    return (
      (DimensionsType as any)[furnitureType as any] || DimensionsType.default
    );
  }

  /**
   * Returns the dimensions per furniture type
   */
  static getDisplay(furnitureType: string | undefined): any[] {
    return (
      (DisplayDimensions as any)[furnitureType as any] ||
      DisplayDimensions.default
    );
  }

  /**
   * Create an array of dimensions
   * @returns array of structure with dimensions
   */
  static createDimensions(str: string, furnitureType: string): object[] {
    const dimensions = str.split('|').map((value: string) => {
      const structure: any = {};
      const list = value.split(',');
      list.forEach((v: string, index: string | number) => {
        structure[this.getType(furnitureType)[index as any]] = parseInt(v) * 10;
      });
      return structure;
    });

    return dimensions;
  }

  /**
   * Create a string of dimensions for several structures
   */
  static get(dimensions: any[], furnitureType: string): string {
    if (!Array.isArray(dimensions)) {
      dimensions = [dimensions];
    }

    const display = dimensions.map((item: { [x: string]: any }) => {
      const dimension = Object.values(this.getType(furnitureType)).map(
        (dimensionType) => {
          return this.convertToCm(item[dimensionType]);
        }
      );
      return dimension.join(',');
    });

    return display.join('|');
  }

  /**
   * Convert to CM and round up
   */
  static convertToCm(
    num: number,
    rounding: 'ceil' | 'floor' | 'round' | 'noRounding' = 'ceil'
  ): number {
    if (!rounding || rounding === 'noRounding') {
      return num / 10;
    }

    return Math[rounding](num / 10);
  }

  /**
   * Generate the min height warning message
   */
  static generateDimensionWarningMsg(
    minHeight: number,
    locale: string
  ): string {
    const translatedString = I18nUtils.localize(
      locale,
      'This product requires a minimum ceiling height of XX cm.'
    );
    const formatedMinHeight =
      DimensionsDisplayUtils.convertToCm(minHeight).toString();
    return translatedString.replace('XX', formatedMinHeight);
  }
}

export default DimensionsDisplayUtils;
