import { PropsWithChildren, PureComponent } from 'react';
import Hammer from 'react-hammerjs';
import classNames from 'classnames';
import styles from './PinchPanZoom.scss';
import { HammerEvent as Event } from './types';

const normalize = (min: number, max: number, val: number) =>
  Math.min(max, Math.max(min, val));

type State = {
  dynamicScale: number;
  dynamicPanning: {
    x: number;
    y: number;
  };
};
type Props = PropsWithChildren<{
  disabled: boolean;
  maxScale: number;
  scale: number;
  fitImageScale: number;
  panning: { x: number; y: number };
  animate: boolean;
  singleTapToZoom?: boolean;
  onZoomUpdate: (zoom: {
    scale?: Props['scale'];
    panning?: Props['panning'];
    animate?: Props['animate'];
  }) => void;
  width: number;
  height: number;
  top: number;
  bottom: number;
  left: number;
  right: number;
}>;

export default class PinchPanZoom extends PureComponent<Props, State> {
  state: State = {
    dynamicScale: 1, // pinching
    dynamicPanning: { x: 0, y: 0 }, // swiping
  };
  static readonly panDirection = 'DIRECTION_ALL'; // by default vertical panning is disabled
  wrapper: HTMLSpanElement | null = null;

  isZoomedIn() {
    return this.props.scale > 1;
  }

  handleTap = (event: Event) => {
    const { maxScale, onZoomUpdate, fitImageScale } = this.props;
    const scale = this.isZoomedIn() ? 1 : maxScale;
    const animate = true;

    const panning = { x: 0, y: 0 };
    if (scale > 1 && this.wrapper) {
      // distance from the edge of the screen to the tap
      const {
        center: { x, y },
      } = event;
      const wrapperRect = this.wrapper.getBoundingClientRect();

      // coordinates in the image
      const tapImageX = x - wrapperRect.left;
      const tapImageY = y - wrapperRect.top;

      // distance to the center of the image
      let distanceX = wrapperRect.width / 2 - tapImageX;
      let distanceY = wrapperRect.height / 2 - tapImageY;

      // distance to the center of the image
      distanceX /= fitImageScale;
      distanceY /= fitImageScale;

      const nPanning = this.normalizePanning({
        x: distanceX,
        y: distanceY,
        scale,
      });
      panning.x = nPanning.x;
      panning.y = nPanning.y;
    }

    onZoomUpdate({ scale, animate, panning });
  };

  handlePinchStart = () => {
    const { onZoomUpdate } = this.props;
    onZoomUpdate({ animate: false });
  };

  handlePinch = (event: Event) => {
    const { type, isFinal, scale: dynamicScale } = event;
    // handlePinch does not always recieve pinchend/isFinal events
    // that's why handlePinchEnd is there. But sometimes Hammer
    // misses calling handlePinchEnd as well. That's why it must be
    // called manually (in case handlePinch hasn't missed the last one)
    // Also from time to time handlePinch receives isFinal with scale 1
    // so the state should not be updated
    if (type === 'pinchend' || isFinal) {
      this.handlePinchEnd();
    } else {
      this.setState({ dynamicScale });
    }
  };

  handlePinchEnd = () => {
    const { maxScale, onZoomUpdate } = this.props;
    let { scale } = this.props;
    let { dynamicScale } = this.state;
    // handlePinchEnd can be called twice (see the comment in handlePinch)
    if (dynamicScale === 1) return;

    scale *= dynamicScale;
    scale = normalize(1, maxScale, scale);
    dynamicScale = 1;

    this.setState({ dynamicScale });
    onZoomUpdate({ scale });
  };

  handlePanStart = () => {
    const { onZoomUpdate } = this.props;
    onZoomUpdate({ animate: false });
  };

  handlePan = (event: Event) => {
    const { scale, fitImageScale } = this.props;
    const { deltaX, deltaY } = event;
    const dynamicPanning = {
      x: deltaX / scale / fitImageScale,
      y: deltaY / scale / fitImageScale,
    };

    this.setState({ dynamicPanning });
  };

  handlePanEnd = () => {
    let { panning, onZoomUpdate, scale } = this.props;
    let { dynamicPanning } = this.state;
    panning = {
      x: panning.x + dynamicPanning.x,
      y: panning.y + dynamicPanning.y,
    };
    dynamicPanning = { x: 0, y: 0 };

    this.setState({ dynamicPanning });

    panning = this.normalizePanning({ x: panning.x, y: panning.y, scale });

    onZoomUpdate({ panning });
  };

  // to avoid moving image outside of FitImage container
  normalizePanning({ x, y, scale }: { x: number; y: number; scale: number }) {
    const { width, height, top, bottom, left, right } = this.props;

    // you can check schema here:
    // https://monosnap.com/file/W8mDhireKP7o508eDblUbJ2maXqqOy
    // https://codepen.io/Denis-k/pen/BGEXbr
    if (scale > 1) {
      // get distance from the center of the original image to the border of FitImage
      const distanceLeft = width / 2 - left;
      const distanceTop = height / 2 - top;
      const distanceRight = width / 2 - right;
      const distanceBottom = height / 2 - bottom;

      // get scaled distances
      const scaledDistanceLeft = distanceLeft * scale;
      const scaledDistanceTop = distanceTop * scale;
      const scaledDistanceRight = distanceRight * scale;
      const scaledDistanceBottom = distanceBottom * scale;

      // Min max value will the difference between scaled and not scaled version
      // and we need to divide by scale to convert value
      const maxX = (scaledDistanceLeft - distanceLeft) / scale;
      const maxY = (scaledDistanceTop - distanceTop) / scale;
      const minX = -(scaledDistanceRight - distanceRight) / scale;
      const minY = -(scaledDistanceBottom - distanceBottom) / scale;

      if (x < minX) x = minX;
      if (x > maxX) x = maxX;

      if (y < minY) y = minY;
      if (y > maxY) y = maxY;
    }

    return { x, y };
  }

  getStyle() {
    const { dynamicScale, dynamicPanning } = this.state;
    const { maxScale, scale: staticScale, panning, animate } = this.props;
    const scale = normalize(1, maxScale, staticScale * dynamicScale);
    let x = panning.x + dynamicPanning.x;
    let y = panning.y + dynamicPanning.y;

    const nPanning = this.normalizePanning({ x, y, scale });
    x = nPanning.x;
    y = nPanning.y;

    const transform =
      `scale(${scale.toFixed(2)}) ` +
      `translateX(${x.toFixed(2)}px) ` +
      `translateY(${y.toFixed(2)}px)`;

    const style = {
      width: '100%',
      height: '100%',
      transform,
      transition: animate ? '.3s ease' : '',
      cursor: this.isEnabledPan() ? 'grab' : '',
    };

    return style;
  }

  isEnabledPan() {
    return !this.props.disabled && this.isZoomedIn();
  }

  render() {
    const { children, disabled, singleTapToZoom } = this.props;
    const { panDirection } = PinchPanZoom;

    const handleSingleTap = singleTapToZoom ? this.handleTap : () => null;
    const handleDoubleTap = singleTapToZoom ? () => null : this.handleTap;
    const enablePan = this.isEnabledPan();

    return (
      <span
        ref={(ref: HTMLSpanElement) => {
          this.wrapper = ref;
        }}
      >
        <Hammer
          onTap={handleSingleTap}
          onDoubleTap={handleDoubleTap}
          onPinchStart={this.handlePinchStart}
          onPinch={this.handlePinch}
          onPinchEnd={this.handlePinchEnd}
          onPanStart={this.handlePanStart}
          onPan={this.handlePan}
          onPanEnd={this.handlePanEnd}
          direction={panDirection}
          options={{
            recognizers: {
              doubletap: { enable: !disabled },
              pan: { enable: enablePan },
              pinch: { enable: !disabled },
            },
          }}
        >
          <div
            className={classNames({
              [styles.allowVerticalScroll]: !enablePan,
            })}
            style={this.getStyle()}
          >
            {children}
          </div>
        </Hammer>
      </span>
    );
  }
}
