import mapValues from 'lodash/mapValues';
import { Component, Fragment } from 'react';

import { OptionsMenuSizeLabel } from 'mycs/configurators/shared/components/OptionsMenu/OptionsMenu';

type Props = {
  children: React.ReactNode;
  width: number;
  height: number;
  top: number;
  bottom: number;
  left: number;
  right: number;
  notScaled?: any;
  onScaleUpdate?: (scale: number) => void;
  optionsMenuSize?: OptionsMenuSizeLabel | null;
  selectAllButton?: JSX.Element | null;
};

export enum Status {
  Animating = 'animating',
  Static = 'static',
}

type State = {
  innerWidth: number;
  innerHeight: number;
  status: Status;
};

export default class FitImage extends Component<Props, State> {
  static defaultProps = {
    children: () => <Fragment />,
  };
  imageContainer: any;
  animationTimeout: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      innerWidth: 0.0,
      innerHeight: 0.0,
      status: Status.Static,
    };
  }

  /**
   * Return styles for scaled children
   */
  getStyle(
    x: number,
    y: number,
    scale: number,
    withScale: boolean,
    status: Status
  ): React.CSSProperties {
    // Shared props between image and rotation elements
    let width;
    let transformOrigin = '0 0';
    let transform = '';
    let transition = '';

    // Image props
    let height;

    // Rotation props
    let position: React.CSSProperties['position'];
    let left = '';
    let right = '';
    let top = '';
    let margin = '';

    if (withScale) {
      // Image properties
      height = this.props.height;
      width = this.props.width;
      transform = `translateY(${y.toFixed(2)}px) translateX(${x.toFixed(
        2
      )}px) scale(${scale.toFixed(2)})`;

      if (status === Status.Animating) {
        transition = 'transform 300ms ease-in-out';
      }
    } else {
      // Rotation properties
      position = 'absolute';
      left = '0';
      right = '0';
      top = '0';
      margin = 'auto';
      transform = `translateY(${this.state.innerHeight.toFixed(2)}px)`;
      transition = 'transform 300ms ease-in-out';
      width = '100%';
    }

    return {
      width,
      height,
      transformOrigin,
      transform,
      transition,
      position,
      left,
      right,
      top,
      margin,
    };
  }

  /**
   * Compute scaling & translation to fit the content
   * of the image in the available space
   */
  fitImage() {
    const { width, height, top, bottom, left, right } = this.props;
    const { innerWidth, innerHeight } = this.state;

    // Return a default value if dimensions are missing
    if (width === 0 || height === 0 || innerWidth === 0 || innerHeight === 0) {
      return { x: 0.0, y: 0.0, scale: 1.0 };
    }

    // Compute x, y, scale
    const contentWidth = width - left - right;
    const contentHeight = height - top - bottom;
    const scaleX = innerWidth / contentWidth;
    const scaleY = innerHeight / contentHeight;

    const heightScaleX = scaleX * contentHeight;
    const widthScaleY = scaleY * contentWidth;

    const center = {
      x: (width - left - right) / 2 + left,
      y: (height - top - bottom) / 2 + top,
    };

    let scale = 1.0;
    if (widthScaleY <= innerWidth) {
      scale = scaleY;
    } else if (heightScaleX <= innerHeight) {
      scale = scaleX;
    }

    const scaledCenter = mapValues(center, (s) => s * scale);

    const x = innerWidth / 2 - scaledCenter.x;
    const y = innerHeight / 2 - scaledCenter.y;

    return { x, y, scale };
  }

  /**
   * Update dimensions
   */
  updateDimensions = () => {
    if (this.imageContainer) {
      const innerWidth = this.imageContainer.offsetWidth;
      const innerHeight = this.imageContainer.offsetHeight;

      if (
        innerWidth !== this.state.innerWidth ||
        innerHeight !== this.state.innerHeight
      ) {
        this.setState({ innerWidth, innerHeight });
      }
    }
  };

  /**
   * Setup listeners
   */
  componentDidMount() {
    this.updateDimensions();

    // Trigger a scale update of the parent
    if (this.props.onScaleUpdate) {
      const { scale } = this.fitImage();
      this.props.onScaleUpdate(scale);
    }

    window.addEventListener('resize', this.updateDimensions);
  }

  /**
   * Call callback when state has changed
   */
  componentDidUpdate(prevProps: Props, prevState: State) {
    // Update internal dimensions
    this.updateDimensions();

    // Check changes of scale dependencies
    if (
      this.state.innerWidth !== prevState.innerWidth ||
      this.state.innerHeight !== prevState.innerHeight ||
      this.props.width !== prevProps.width ||
      this.props.height !== prevProps.height ||
      this.props.top !== prevProps.top ||
      this.props.bottom !== prevProps.bottom ||
      this.props.left !== prevProps.left ||
      this.props.right !== prevProps.right
    ) {
      // Trigger a scale update of the parent
      if (this.props.onScaleUpdate) {
        const { scale } = this.fitImage();
        this.props.onScaleUpdate(scale);
      }
    } else {
      // Manage the animation of the image size
      if (this.props.optionsMenuSize !== prevProps.optionsMenuSize) {
        this.setState({ status: Status.Animating }, () => {
          this.animationTimeout = setTimeout(() => {
            this.setState({ status: Status.Static });
          }, 300);
        });
      }
    }
  }

  /**
   * Remove event listener
   */
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);

    if (this.animationTimeout) {
      clearTimeout(this.animationTimeout);
    }
  }

  render() {
    const { children, notScaled, selectAllButton } = this.props;
    const { status } = this.state;

    const style = {
      height: '100%',
      width: '100%',
    };

    // Compute position and scale
    const { x, y, scale } = this.fitImage();

    return (
      <div
        style={style}
        ref={(root) => {
          this.imageContainer = root;
        }}
      >
        <div style={this.getStyle(x, y, scale, true, status)}>{children}</div>
        {(notScaled || selectAllButton) && (
          <div style={this.getStyle(x, y, scale, false, status)}>
            {selectAllButton}
            {notScaled}
          </div>
        )}
      </div>
    );
  }
}
