import { Fragment } from 'react';

import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';
import { useLocale } from 'mycs/shared/state/LocaleContext';

import styles from './ImageCredit.scss';

export type IImageCredit = {
  imageUrl: string;
  credit?: string;
};

export function ImageCredit({ credit }: { credit?: string }) {
  const { locale } = useLocale();

  if (!credit) return <Fragment />;

  return (
    <div className={styles.imageCredit}>
      {I18nUtils.localize(locale, 'by')} {credit}
    </div>
  );
}
