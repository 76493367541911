import { PureComponent } from 'react';
import classNames from 'classnames';

import { useLocale } from 'mycs/shared/state/LocaleContext';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';
import DimensionsDisplayUtils from 'mycs/shared/utilities/DimensionsDisplayUtils/DimensionsDisplayUtils';

import styles from './Dimensions.scss';

type Props = {
  dimensions: any[] | object;
  furnitureType?: string;
  inline?: boolean;
  compact?: boolean;
  className?: string;
  rounding?: 'ceil' | 'floor' | 'round' | 'noRounding';
};

export default function Dimensions(props: Props) {
  const { locale } = useLocale();
  return <InnerDimensions {...props} locale={locale} />;
}

type InnerProps = Props & { locale: string };

export class InnerDimensions extends PureComponent<InnerProps> {
  dimensionsType: any[];
  dimensionsDisplay: any[];

  constructor(props: InnerProps) {
    super(props);
    this.dimensionsType = DimensionsDisplayUtils.getType(
      this.props.furnitureType
    );
    this.dimensionsDisplay = DimensionsDisplayUtils.getDisplay(
      this.props.furnitureType
    );
  }

  /**
   * Set the dimensions in cm
   */
  getDimensionsCM(): any[] {
    let dimensions = this.props.dimensions;
    if (!Array.isArray(this.props.dimensions))
      dimensions = [this.props.dimensions];

    return (dimensions as any[]).map((dimension) => {
      const dim = {};
      // Height, length, width
      Object.keys(dimension).forEach((dimensionType) => {
        (dim as any)[dimensionType] = DimensionsDisplayUtils.convertToCm(
          dimension[dimensionType],
          this.props.rounding
        );
      });

      return dim;
    });
  }

  /**
   * Display separation character for inline dimensions
   */
  displaySeparationCharacter(dimensionIdx: number): string | void {
    if (this.props.inline && dimensionIdx < this.dimensionsDisplay.length - 1) {
      return ', ';
    }
  }

  /**
   * Display the first character of a string
   */
  displayFirstCharacter(dimensionDisplay: string): string {
    return I18nUtils.localize(this.props.locale, dimensionDisplay).charAt(0);
  }

  /**
   * Render the dimension index number
   */
  renderDimensionIndex(dimensionsCM: any[], dimensionIdx: number): JSX.Element {
    if (dimensionsCM.length > 1) {
      return <div>({dimensionIdx + 1}) </div>;
    }
    return <div></div>;
  }

  render() {
    const { dimensions } = this.props;

    if (
      !dimensions ||
      (Array.isArray(dimensions) && dimensions.includes(null))
    ) {
      return <></>;
    }

    const dimensionsInlineClass = classNames({
      [styles.dimensionsInline]: this.props.inline,
      [styles.compact]: this.props.compact,
    });
    const dimensionsCM = this.getDimensionsCM();

    return (
      <div className={styles.dimensions}>
        {dimensionsCM.map((dimension, idx) => (
          <div key={idx} className={dimensionsInlineClass}>
            {this.renderDimensionIndex(dimensionsCM, idx)}
            {this.dimensionsDisplay.map((dimensionDisplay, dimensionIdx) => (
              <div key={dimensionIdx} className={this.props.className}>
                {this.displayFirstCharacter(dimensionDisplay)}:&nbsp;
                {dimension[this.dimensionsType[dimensionIdx]]}
                {this.props.compact ? 'cm' : ' cm'}
                {this.displaySeparationCharacter(dimensionIdx)}
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  }
}
